<template>
    <div>
        <iframe class="ifabox" src="http://182.87.219.138:4324/" frameborder="0"></iframe>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style scoped>
.ifabox{ width: 100%; height: 100%; height: 820px;}
</style>